<!-- eslint-disable vue/v-on-handler-style -->
<template>
  <v-navigation-drawer
    v-model="drawerIsOpen"
    disable-resize-watcher
    order="0"
    width="450"
    elevation="0"
    location="end"
    @update:model-value="close()"
  >
    <template #prepend>
      <v-card v-if="selectedBuild" class="pa-0">
        <v-card-title class="pt-2 pb-0">
          <h3 class="d-flex font-weight-regular align-baseline">
            {{ isReleaseCandidate ? 'Release candidate build' : 'Recent build' }}
          </h3>

          <v-spacer />

          <v-btn rounded icon="mdi-close" color="primary" class="mt-n2 mr-n4" @click="close()" />
        </v-card-title>

        <v-card-subtitle class="mb-2">
          <p>
            {{ selectedBuild.platform === 'ios' ? 'iOS' : 'Android' }} | Build {{ selectedBuild.buildVersion }} |
            Version
            {{ selectedBuild.version }}
          </p>
        </v-card-subtitle>
      </v-card>

      <v-divider />
    </template>

    <v-card v-if="selectedBuild" class="mt-n4">
      <v-timeline v-model="nextStep" side="end" align="start" density="compact" size="small">
        <StepTechnicalStatus :next-step="nextStep" :selected-build="selectedBuild" @output="updateStep($event)" />

        <StepReleaseTier
          :next-step="nextStep"
          :selected-build="selectedBuild"
          :is-complete="stepState.priorityGroupTesting"
          :release-tier="ReleaseTiers.PRIORITY_GROUP_TESTING"
        />

        <StepReleaseTier
          :next-step="nextStep"
          :selected-build="selectedBuild"
          :is-complete="stepState.internalTesting"
          :release-tier="ReleaseTiers.INTERNAL_TESTING"
        />

        <StepReleaseTier
          :next-step="nextStep"
          :selected-build="selectedBuild"
          :is-complete="stepState.externalTesting"
          :release-tier="ReleaseTiers.FULL_EXTERNAL_TESTING"
        />

        <StepReleaseNotes :next-step="nextStep" :selected-build="selectedBuild" :is-complete="stepState.releaseNotes" />

        <StepReleaseState
          :next-step="nextStep"
          :selected-build="selectedBuild"
          :is-complete="stepState.review && stepState.rollout"
        />
      </v-timeline>
    </v-card>

    <template v-if="selectedBuild" #append>
      <div class="d-flex pa-2 pt-4 justify-center">
        <v-btn
          rounded="1"
          variant="flat"
          :loading="editingRcStatus"
          :color="isReleaseCandidate ? 'error' : 'primary'"
          :disabled="!isReleaseCandidate && !eligibleAsReleaseCandidate"
          :text="isReleaseCandidate ? 'Remove release candidate status' : 'Select as release candidate'"
          @click="confirmRcStatusUpdate()"
        />
      </div>
    </template>
  </v-navigation-drawer>

  <ReleaseNotesDialog
    v-if="drawerIsOpen"
    v-model:open="isReleaseNotesDialogOpen"
    v-model:tab="selectedBuild.platform"
    :allow-tab-switch="false"
    :type="ReleaseTypes.CANDIDATE"
    @close="releaseNotesDialogClosed($event)"
  />
</template>

<script lang="ts">
  import { upperFirst } from 'lodash'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { adjustAndroidRollout, changeReviewStatus, compareBuildTiers, getNextStep } from '#views/releases/utilities'

  import { AppStore, ReleasesStore } from '#stores'

  import {
    AppBuild,
    PlayStoreReleaseStatuses,
    ReleaseNoteStates,
    ReleaseStates,
    ReleaseStepStates,
    ReleaseSteps,
    ReleaseTiers,
    ReleaseTypes,
  } from '#types/releases'

  @Component({})
  class ReleasePanel extends Vue {
    @Prop({ required: true }) public selectedBuild!: AppBuild

    public drawerIsOpen = false
    public editingRcStatus = false
    public isReleaseNotesDialogOpen = false
    public stepState = this.defaultStepState
    public nextStep = ReleaseStepStates.TECHNICAL_STATUS

    public readonly ReleaseTiers = ReleaseTiers
    public readonly ReleaseTypes = ReleaseTypes

    private readonly appStore = new AppStore()
    private readonly releasesStore = new ReleasesStore()

    public get isAppReleaseAdmin() {
      return this.appStore.isAppReleaseAdmin
    }

    public get eligibleAsReleaseCandidate() {
      return Number(this.releaseCandidates[this.selectedBuild.platform]) < Number(this.selectedBuild.buildVersion)
    }

    public get isReleaseCandidate() {
      return this.releaseCandidates[this.selectedBuild.platform] === this.selectedBuild.buildVersion
    }

    private get releaseCandidates() {
      return this.releasesStore.releaseCandidates
    }

    private get releaseNotes() {
      return this.releasesStore?.releaseNotes?.[ReleaseTypes.CANDIDATE]?.[this.selectedBuild.platform]
    }

    private get defaultStepState() {
      const buildTier = this.selectedBuild?.tier

      return {
        technicalStatusIsValid: false,
        priorityGroupTesting: compareBuildTiers(buildTier, ReleaseTiers.PRIORITY_GROUP_TESTING) === 1,
        internalTesting: compareBuildTiers(buildTier, ReleaseTiers.INTERNAL_TESTING) === 1,
        externalTesting: compareBuildTiers(buildTier, ReleaseTiers.FULL_EXTERNAL_TESTING) > -1,
        releaseNotes: this.releaseNotes?.state === ReleaseNoteStates.TRANSLATED,
        review: !!this.selectedBuild?.state,
        rollout: false,
      } as ReleaseSteps
    }

    private get isInReview() {
      return (
        this.stepState.externalTesting &&
        this.stepState.releaseNotes &&
        this.selectedBuild?.state !== ReleaseStates.PENDING_FOR_RELEASE
      )
    }

    private get readyForReview() {
      return (
        this.stepState.externalTesting &&
        this.stepState.releaseNotes &&
        this.selectedBuild?.state === ReleaseStates.PREPARE_FOR_SUBMISSION
      )
    }

    private get releaseCandidateConfirmText() {
      if (this.isReleaseCandidate) {
        return this.isInReview
          ? 'Release candidate status will be removed and review process STOPPED.'
          : 'Release candidate status will be removed.'
      } else {
        return this.readyForReview
          ? 'Release candidate status will be added and build will be submitted for review.'
          : 'Release candidate status will be added.'
      }
    }

    @Watch('selectedBuild')
    public selectedBuildChanged() {
      this.drawerIsOpen = !!this.selectedBuild

      if (this.drawerIsOpen) {
        this.stepState = this.defaultStepState
        this.updateStep(this.stepState)
      }
    }

    @Emit('close')
    public close() {
      return undefined
    }

    public updateStep(newStepStatus: Partial<ReleaseSteps>) {
      this.stepState = { ...this.stepState, ...newStepStatus }
      this.nextStep = getNextStep(this.stepState)
    }

    public releaseNotesDialogClosed(releaseNotesSaved: boolean) {
      this.isReleaseNotesDialogOpen = false

      if (releaseNotesSaved) {
        this.confirmRcStatusUpdate()
      }
    }

    /**
     * Validate and confirm release candidate status change
     */
    public async confirmRcStatusUpdate() {
      if (!this.isReleaseCandidate && this.releaseNotes === undefined) {
        const addReleaseNotes = await this.$confirm(
          'Add initial release notes?',
          '<p>Release candidate must have release notes set, do you want to add initial release notes now?</p>',
        )

        if (addReleaseNotes) {
          this.isReleaseNotesDialogOpen = true
        }

        return
      }

      const updateRcStatus = await this.$confirm(
        this.isReleaseCandidate ? 'Remove release candidate status?' : 'Select as release candidate?',
        `<p>${upperFirst(this.selectedBuild.platform)} | ${this.selectedBuild.buildVersion}</p><p>${this.releaseCandidateConfirmText}</p>`,
      )

      if (updateRcStatus) {
        this.updateRcStatus()
      }
    }

    /**
     * Update release candidate status
     * - If build is selected as release candidate, remove it from release candidates
     * - If build is not selected as release candidate, set it as release candidate
     * - If build is in review, cancel review
     * - If build is halted, resume rollout
     */
    private async updateRcStatus() {
      this.editingRcStatus = true

      const {
        version,
        platform,
        versionId,
        buildVersion,
        rolloutPercentage,
        reviewSubmissionId,
        reviewSubmissionItemId,
      } = this.selectedBuild

      await this.releasesStore.setReleaseCandidate(this.isReleaseCandidate ? '' : buildVersion, platform)

      this.editingRcStatus = false

      if (this.isReleaseCandidate && this.isInReview) {
        await changeReviewStatus('cancel', {
          version,
          platform,
          buildVersion,
          reviewSubmissionId,
          reviewSubmissionItemId,
        })
      } else if (this.isReleaseCandidate && rolloutPercentage) {
        await adjustAndroidRollout(PlayStoreReleaseStatuses.HALTED, { buildVersion, rolloutPercentage })
      } else if (!this.isReleaseCandidate && this.readyForReview) {
        await changeReviewStatus('submit', {
          version,
          platform,
          versionId,
          buildVersion,
          reviewSubmissionId,
          reviewSubmissionItemId,
        })
      }
    }
  }

  export default toNative(ReleasePanel)
</script>
