import { getApp } from 'firebase/app'
import {
  DocumentData,
  SetOptions,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

type DocWithId<T = DocumentData> = T & { id: string }

export const cloudFunction: <T>(functionName: string, data: any) => Promise<T | undefined> = async (
  functionName: string,
  data: any,
) => {
  try {
    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, functionName)(data)

    return response.data
  } catch (_error) {
    return undefined
  }
}

/**
 * @returns A document with its Firestore id if provided data does not override the id.
 */
const docWithId = <T>(doc: DocumentData): DocWithId<T> => {
  return {
    id: doc.id,
    ...doc.data(),
  }
}

/**
 * Format data to be written to Firestore.
 *
 * - Adds `id` field with the provided docId if not overriden by an id in data.
 * - Adds `createdAt` field with the current server timestamp if not provided.
 * - Updates `updatedAt` field with the current server timestamp.
 */
const fsDataFormatter = (docId: string, data: any) => {
  return {
    id: docId,
    ...data,
    updatedAt: serverTimestamp(),
    ...(Object.hasOwn(data, 'createdAt') ? {} : { createdAt: serverTimestamp() }),
  }
}

export const docRef = (docPath: string, docId: string) => {
  return doc(getFirestore(), `/${docPath}/${docId}`)
}

export const collectionRef = (collectionPath: string) => {
  return collection(getFirestore(), collectionPath)
}

const getFsDoc = (docPath: string, docId: string) => {
  return getDoc(docRef(docPath, docId))
}

export const getFsDocData = async <T = DocumentData>(docPath: string, docId: string) => {
  const doc = await getFsDoc(docPath, docId)

  return docWithId<T>(doc)
}

const getFsCollection = (collectionPath: string) => {
  return getDocs(collectionRef(collectionPath))
}

export const getFsCollectionData = async <T = DocumentData>(docPath: string) => {
  const { docs } = await getFsCollection(docPath)

  return docs.map((doc) => docWithId<T>(doc))
}

/**
 * Set data to a Firestore document.
 * Adds docId under 'id' key in the data if data does not have a value for key 'id'.
 * Appends `updatedAt` field with the current server timestamp.
 *
 * @param docPath Path to the document in Firestore.
 * @param id Id of the document. This will be overriden if a different id is provided in the data.
 * @param data Data to be written to the document.
 * @param options Options to configure the set behavior. { merge: true } by default
 *
 * @returns A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export const setFsDocData = (docPath: string, docId: string, data: any, options: SetOptions = { merge: true }) => {
  return setDoc(docRef(docPath, docId), fsDataFormatter(docId, data), options)
}

/**
 * Update data to a Firestore document.
 * Adds docId under 'id' key in the data if data does not have a value for key 'id'.
 * Appends `updatedAt` field with the current server timestamp.
 * The update will fail if applied to a document that does not exist.
 *
 * @param docPath Path to the document in Firestore.
 * @param id Id of the document. This will be overriden if a different id is provided in the data.
 * @param data Data to be written to the document.
 *
 * @returns A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export const updateFsDocData = (docPath: string, docId: string, data: any) => {
  return updateDoc(docRef(docPath, docId), fsDataFormatter(docId, data))
}
