<template>
  <v-timeline-item width="100%" :dot-color="stepColor(nextStep, isComplete, ReleaseStepStates.ROLLOUT)">
    <h2 class="d-flex flex-start">Review process</h2>

    <v-row dense>
      <v-col cols="12" class="d-flex align-center justify-space-between flex-wrap">
        <p class="text-subtitle-2 text-grey-darken-1 font-weight-regular">Review and release status of the build.</p>

        <v-spacer />

        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-chip v-bind="props" link variant="text" target="_blank" rel="noopener noreferrer" :href="appStoreLink">
              <v-icon icon="mdi-open-in-new" />
            </v-chip>
          </template>

          <span>Open {{ appStoreName }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <template v-if="isActive">
      <template v-if="readyToSubmit">
        <p>Build is ready to be submitted for review</p>

        <v-btn
          rounded
          class="mt-4"
          color="primary"
          variant="outlined"
          text="Submit build for review"
          append-icon="mdi-arrow-right"
          :loading="submittingForReview"
          :disabled="!isAppReleaseAdmin"
          @click="submitForReview(selectedBuild)"
        />

        <v-alert v-if="submissionError" type="error" :text="submissionError" />
      </template>

      <template v-else-if="selectedBuild.state === ReleaseStates.WAITING_FOR_REVIEW">
        <p>
          <v-icon color="info" icon="mdi-clock-time-four-outline" />

          Waiting for review>
        </p>
      </template>

      <template v-else-if="selectedBuild.state === ReleaseStates.PENDING_FOR_RELEASE">
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <p v-bind="props">
              <v-icon color="success" icon="mdi-check-circle" />

              <template v-if="selectedBuild.platform === 'ios'">
                Public release rollout may be started from App Store Connect.
              </template>

              <template v-if="selectedBuild.platform === 'android'">
                Staged rollout in progress ({{ (selectedBuild?.rolloutPercentage || 0) * 100 }}%).
              </template>
            </p>
          </template>

          <span v-if="selectedBuild.platform === 'android'">
            Rollout status may not be accurate due to Google Play Console API limitations.
          </span>
        </v-tooltip>
      </template>

      <template v-else-if="selectedBuild.state === ReleaseStates.CURRENT_PUBLIC_RELEASE">
        <p>
          <v-icon color="success">mdi-check-circle</v-icon>

          Full public rollout in progress
        </p>
      </template>
    </template>
  </v-timeline-item>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { changeReviewStatus, stepColor } from '#views/releases/utilities'

  import { AppStore } from '#stores'

  import { AppBuild, ReleaseStates, ReleaseStepStates } from '#types'

  @Component({})
  class StepReleaseState extends Vue {
    @Prop({ required: true }) public nextStep!: number

    @Prop({ default: false }) public isComplete!: boolean

    @Prop({ required: true }) public selectedBuild!: AppBuild

    public submissionError = ''
    public submittingForReview = false

    public readonly stepColor = stepColor

    public readonly ReleaseStates = ReleaseStates

    public readonly ReleaseStepStates = ReleaseStepStates

    private readonly appStore = new AppStore()

    public get isActive() {
      return this.nextStep === ReleaseStepStates.ROLLOUT
    }

    public get isAppReleaseAdmin() {
      return this.appStore.isAppReleaseAdmin
    }

    public get appStoreName() {
      return this.selectedBuild.platform === 'ios' ? 'App Store Connect' : 'Google Play Console'
    }

    public get appStoreLink() {
      if (this.selectedBuild.platform === 'ios') {
        return 'https://appstoreconnect.apple.com/apps/1043837948/distribution/ios/version/inflight'
      } else {
        return 'https://play.google.com/console/u/0/developers/6670494686022964728/app/4973924371462496600/releases/overview'
      }
    }

    public get readyToSubmit() {
      return (
        this.selectedBuild.state === ReleaseStates.PREPARE_FOR_SUBMISSION &&
        (this.selectedBuild?.rolloutPercentage ?? 0) <= 0
      )
    }

    public async submitForReview(build: AppBuild) {
      const { version, versionId, buildVersion, buildId, platform, reviewSubmissionId, reviewSubmissionItemId } = build

      if (!version || !buildVersion || !platform || !buildId) {
        return
      }

      const confirm = await this.$confirm(
        'Submit build for review?',
        `Platform: ${platform === 'ios' ? 'iOS' : 'Android'} | Version: ${version} | Build: ${buildVersion}`,
      )

      if (confirm) {
        this.submittingForReview = true

        await changeReviewStatus('submit', {
          buildVersion,
          version,
          versionId,
          platform,
          reviewSubmissionId,
          reviewSubmissionItemId,
        })
          .catch((error) => {
            this.submissionError = `Review submission failed, try again. (${error})`
          })
          .finally(() => {
            this.submittingForReview = false
          })
      }
    }
  }

  export default toNative(StepReleaseState)
</script>
